$-theme-header-primary-color: ghostwhite;
$-theme-text-primary-color: #E4DFDA;
$-theme-black: #040810;
$-theme-primary-blue: #102141;
$-theme-secondary-blue: #777DA7;
$-theme-action-red: red;
$-theme-secondary-red: #8F0002;


// /* // Scrollbar Styles and animation */

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: $-theme-primary-blue;
  margin-block: -.5em;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: $-theme-secondary-blue;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $-theme-secondary-red;
}

/** Global Styles **/


.cursor-default {
  cursor: default;
}

.subtext {
  position: relative;
  font-size: .75rem;
  margin-right: 12px;
  color: #777DA7;
}

.subtext:before {
  color: red;
  // color: #B72E31;
  font-family: "Roboto Mono", monospace;
}

.post-subtext {
  position: absolute;
  right: 13.4em;
}

.red-flavor {
  color: #8F0002;
}

.secondary-red {
  color: $-theme-secondary-red;
}

.secondary-blue {
  color: $-theme-secondary-blue;
}

.bolded {
  font-weight: bold;
  letter-spacing: .1em;
}

.italicized {
  font-style: italic;
}

.bottom-space {
  height: 20vh;
  background-color: #0f111a;
}

.no-wrap {
  white-space: no-wrap;
}

/* Global Colors */
.theme-text-content {
  color: #E4DFDA;
}

.theme-black {
  background-color: #040810;
}

.theme-main-blue {
  background-color: #102141;
}

.theme-secondary-blue {
  background-color: #777DA7;
}

.theme-purple {
  background-color: #8850bf;
}
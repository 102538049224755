@import '../../globalstyles';

#experience-component {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  min-height: 1000px;
}

.experience-header {
  font-family: 'Julius Sans One', sans-serif;
  font-size: 2em;
  font-weight: bold;
  margin: auto;
  margin-top: 0;
  width: 50%;
  padding: 32px 0;
  box-shadow: -0px 50px 45px #060d1a;
}

// .experience-header:before {
//   content: "";
//   position: absolute;
//   top: 54px;
//   left: 2vw;
//   height: 2px;
//   width: 17vw;
//   background: rgb(167, 188, 214);
// }


// .experience-header:after {
//   content: "";
//   position: absolute;
//   top: 54px;
//   right: 2vw;
//   height: 2px;
//   width: 17vw;
//   background: rgb(167, 188, 214);
// }


.experience-header .subtext:before {
  counter-increment: section 1;
  content: '0'counter(section) ".";
  position: absolute;
  font-size: .75rem;
  top: -24px;
  font-family: 'Roboto Mono', monospace;
}


.experience-text {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 40px;
  font-size: 25rem;
  color: rgba(167, 188, 214, .04);
  white-space: nowrap;
  z-index: -1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.experience-module-container {
  box-sizing: border-box;
  max-width: 1000px;
  min-height: 600px;
  margin: auto;
}

.experience-module {
  display: flex;
  flex-direction: row;
  height: 500px;
  min-width: 1000px;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.experience-sidebar-wrapper {
  position: relative;
  height: inherit;
}

.experience-sidebar {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;
  font-size: 20px;
  background: linear-gradient(145deg, #0e1e3b, #112346);
  box-shadow: 44px 44px 67px #060d1a,
    -44px -44px 67px #1a3568;
}

.experience-sidebar li {
  padding: 20px;
  margin: 20px;
  border-radius: 50vw;
  background: linear-gradient(145deg, #112346, #0e1e3b);
  box-shadow: 14px 14px 28px #060d1a, -14px -14px 28px #1a3568;
  -webkit-border-radius: 50vw;
  -moz-border-radius: 50vw;
  -ms-border-radius: 50vw;
  -o-border-radius: 50vw;
  cursor: pointer;
}

.experience-content-wrapper {
  display: flex;
  flex: 7;
  height: 100%;
  padding: 0 56px 0 0;
  background: #10214199;
  box-shadow: inset 44px 44px 67px #060d1a,
    inset -44px -44px 67px #1a3568;
}

.company-list {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
}

.company-list li {
  position: absolute;
  width: 100%;
  transition: 3s;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  -ms-transition: 3s;
  -o-transition: 3s;
}

.contentActive-true {
  top: 0;
  left: 0;
  padding: 40px 0 0 0;
  transition: 3s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.contentActive-false {
  left: -100vw;
  transition: .5s;
  padding: 40px 0 0 0;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.experience-sidebar .button-active-true {
  background: linear-gradient(145deg, #112346, #0e1e3b);
  box-shadow: inset 14px 14px 28px #060d1a, inset -14px -14px 28px #1a3568;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

// .experience-sidebar .button-active-false {
//   // background: linear-gradient(145deg, #0e1e3b, #112346);
//   // box-shadow: 14px 14px 28px #060d1a, -14px -14px 28px #1a3568;
// }

.company {
  &__title-link {
    display: flex;
    text-align: left;
    width: 80%;
    margin: 20px 0 20px auto;
    justify-content: space-between;
    align-items: center;
    color: $-theme-text-primary-color;
  }

  &__title {
    margin: 0;
  }

  &__link {
    color: $-theme-secondary-blue;
    font-size: 16px;
    text-decoration: none;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
  }

  &__link:hover {
    color: red;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
  }
}

.company-title,
.company-name,
.company-date {
  text-align: left;
  width: 80%;
  margin: 20px 0 20px auto;
}


.company-title {
  font-size: 28px;
}


.company {
  &__name-date {
    display: flex;
    width: 80%;
    text-align: left;
    margin: 0 0 20px auto;
    align-items: center;
    border-bottom: 1px solid $-theme-secondary-blue;
  }

  &__name {
    align-self: baseline;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }

  &__date {
    align-self: baseline;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-left: 24px;
  }
}

.company-description {
  text-align: left;
  width: 80%;
  margin-left: auto;
  padding: 0;
  list-style-type: none;
  color: $-theme-text-primary-color;
}

.company-description li {
  position: relative;
  margin-bottom: 12px;
  font-size: 16px;
}

.company-description li:before {
  content: '\25B8';
  position: absolute;
  left: -24px;
  top: 0;
  font-size: 24px;
  color: $-theme-secondary-red;
}

@media (max-width: 800px) {

  .experience-header {
    width: 80%;
  }

  .experience-module-container {
    width: 100%;
  }

  .experience-module {
    flex-direction: column;
    height: 100%;
    min-width: 0;
    padding: 0;
  }

  .experience-sidebar {
    flex-direction: row;
    font-size: 14px;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
  }

  .experience-sidebar li {
    padding: 20px 10px;
    vertical-align: middle;
    min-width: 20%;
  }

  .company-list {
    overflow: hidden;
    height: 550px;
    width: 500px;
  }

  .experience-content-wrapper {
    display: flex;
    flex: 7 1;
    height: 1000px;
    width: 80%;
    padding: 0 56px 0 0;
    background: rgba(16, 33, 65, 0.6);
    box-shadow: inset 44px 44px 67px #060d1a, inset -44px -44px 67px #1a3568;
  }

  .contentActive-true {
    top: 0;
    left: 0;
    padding: 40px 0 0 0;
  }

  .contentActive-false {
    top: -100vh;
    left: 0;
    transition: .5s;
    padding: 40px 0 0 0;
  }

  .company__title-link {
    flex-direction: column;

  }
}

// .experience-page-intro {
//   height: 160px;
//   padding: 0 10vw;
//   font-size: .95rem;
//   text-align: left;
// }

// .experience-section {
//   font-weight: 100;
//   letter-spacing: 0.05em;
//   min-height: 50vh;
// }

// .experience-section * {
//   position: relative;
// }

// .accordian {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   height: 50%;
// }

// .accordian-item {
//   width: 80%;
//   height: 60px;
//   // background: #FFA9E7;
//   // color: #090B10;
//   background: #00000099;
//   border: 2px solid white;
//   color: $-theme-header-primary-color;
//   letter-spacing: .1em;
//   padding: 0 2%;
//   border-radius: .5em;
// }

// .accordian-item-title-wrapper {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   height: 100%;
// }

// .accordian-item-title-wrapper>h6 {
//   width: 90%;
//   margin: 0;
// }

// .accordian-item-title {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
// }

// .accordian-expand-button {
//   cursor: pointer;
//   color: aliceblue;
//   font-weight: 400;
//   height: 30px;
//   width: 30px;
//   font-size: 32px;
//   line-height: .8;
//   box-shadow: 0 0 5px 4px aliceblue;
//   background: #8F0002;
//   border-radius: 100%;
//   -webkit-border-radius: 100%;
//   -moz-border-radius: 100%;
//   -ms-border-radius: 100%;
//   -o-border-radius: 100%;
// }

// .accordian-expand-button p {
//   margin: 0 0 0px 2px;
//   pointer-events: none;
//   line-height: 24px;
// }

// .accordian-content {
//   display: flex;
//   justify-content: center;
//   width: 70%;
//   text-align: left;
//   padding: 0 0 0 3%;
//   margin: 0 0 5% 0;
//   color: $-theme-header-primary-color;
//   font-weight: 400;
//   background: #090b10;
//   // background: #270901;
//   z-index: -1;
//   height: 250px;
//   border-bottom-left-radius: 20px;
//   border-bottom-right-radius: 20px;
//   border: 1px solid white;
//   overflow: hidden;
//   transition: .5s;
// }

// .content-false {
//   height: 10px;
//   padding: 0;
//   background: #090b10;
// }

// .accordian-details {
//   padding: 3% 0;
//   max-width: 85%;
//   // min-width: 70%;
// }

// .accordian-false {
//   opacity: 0;
//   transition: .5s;
//   -webkit-transition: .5s;
//   -moz-transition: .5s;
//   -ms-transition: .5s;
//   -o-transition: .5s;
// }

// .accordian-true {
//   opacity: 1;
//   padding-right: 5%;
//   transition: .5s;
//   -webkit-transition: .5s;
//   -moz-transition: .5s;
//   -ms-transition: .5s;
//   -o-transition: .5s;
// }

// .company-info {
//   color: gray;
//   margin-bottom: 10px;
// }

// .company-info svg {
//   height: 20px;
//   fill: #FB9F89;
//   padding: 0 10px 0 0;
// }

// .company-info a {
//   text-decoration: none;
//   color: gray;
//   font-size: .75em;
// }

// .job-technologies ul {
//   list-style: none;
//   padding: 0;
//   margin: 15px 0 10px 0;
//   font-size: 20px;
//   color: lightgray;

// }

// .job-technologies ul li:first-child {
//   margin-left: 0;
// }

// .job-technologies ul li {
//   float: left;
//   margin: 1%;
//   background: #112240;
//   border-radius: 15px;
//   padding: .5% 1.5%;
//   font-size: .8em;
//   font-weight: 500;
// }

// .job-description {
//   color: lightgray;
//   font-size: .6em;
// }

// .company-logo {
//   height: 40%;
//   margin: auto 5% auto 0;
//   transition: .5s;

// }

// .company-logo>img {
//   height: 100%;
//   transition: .5s;

// }

// .logo-container {
//   width: 40%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   background: white;
// }

// .guildmortgage-logo-wrapper {
//   width: 100%;
//   margin: auto 0;
//   transition: .5s;
//   text-align: center;
//   max-width: 300px;
// }

// .guildmortgage-logo-wrapper>img {
//   // width: 60%;
//   // height: initial;
//   // transition: .5s;
//   aspect-ratio: 3/2;
//   object-fit: contain;
// }

// .guild-false {
//   width: 0px !important;
//   height: 0px !important;
//   transition: .5s !important;
// }

// .supplypro-logo-container {
//   width: 48%;
// }

// .supplypro-logo-wrapper {
//   width: 88%;
//   margin: auto 0;
//   transition: .5s;
//   text-align: center;
//   max-width: 230px;
// }

// .supplypro-logo-wrapper>img {
//   width: 100%;
//   height: initial;
//   transition: .5s;
// }

// .supplyPro-false {
//   width: 0px !important;
//   height: 0px !important;
//   transition: .5s !important;
//   -webkit-transition: .5s !important;
//   -moz-transition: .5s !important;
//   -ms-transition: .5s !important;
//   -o-transition: .5s !important;
// }

// .imatrix-logo-container {
//   width: 64%;
// }

// .imatrix-logo-wrapper {
//   width: 100%;
//   margin: auto 0;
//   transition: .5s;
//   text-align: center;
//   max-width: 275px;
// }

// .imatrix-logo-wrapper>img {
//   width: 80%;
//   max-width: 88%;
//   height: initial;
//   transition: .5s;
// }

// .iMatrix-false {
//   width: 0px !important;
//   height: 0px !important;
//   transition: .5s !important;
// }

// @media (max-width:1000px) {
//   .page-split {
//     flex-direction: column;
//   }

//   .company-logo {
//     height: initial;
//     margin: 5% 0 0 0;
//   }

//   .imatrix-logo-wrapper {
//     width: 55%;
//   }

//   .experience-animation-wrapper {
//     margin-top: 5vh;
//   }

//   .accordian-content {
//     flex-direction: column;
//     padding: 0;
//   }

//   .accordian-details {
//     padding-left: 3%;
//   }

//   .guildmortgage-logo-wrapper {
//     width: 35%;
//   }

//   .supplypro-logo-wrapper {
//     width: 55%;
//   }

//   .logo-container {
//     width: 100%;
//     align-items: flex-start;
//   }

//   .content-true {
//     height: 400px;
//   }

//   .job-technologies ul li {
//     margin: 1.5%;
//   }
// }



// @media (max-width:600px) {
//   .company-logo {
//     height: initial;
//   }

//   .content-true {
//     height: 320px;
//   }

//   .guildmortgage-logo-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .guildmortgage-logo-wrapper>img {
//     width: 70%;
//   }

//   .imatrix-logo-wrapper {
//     width: 70%;
//   }
// }
@import "../../_globalstyles";

.navbar-component-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
}

.navbar-component {
  top: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: #112240;
  transition: .5s;
  z-index: 500;
  box-shadow: 0 0 15px 15px #112240;
}

/* .navbar-component a, 
.navbar-component li, 
.navbar-component .smaller, 
.navbar-component button {
  cursor: url("../../Assets/white_dot.png"), auto!important;
 } */
.background-blur {
  position: absolute;
  width: 100vw;
  height: 100vh;

}

.nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  background-color: #01020a;
}

/* Initials */

.initials-icon {
  display: inline-block;
  position: relative;
  transition: .55s;
}

.initials-icon:hover {
  background: $-theme-secondary-red;
  /* background: #b72e31; */
  /* background: #A7BCD6; */
}

.initials-subscript {
  font-family: 'Julius Sans One', sans-serif;
}

.initials-subscript span {
  font-weight: bold;
  font-size: 1.25rem;
}

.nav-comp-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(10px);
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

/* 
.initials-icon:after {
  position: absolute;
  content: "Julius Dorfman";
  font-size: 10px;
  left: 1px;
}  */

.icon-card-initials-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 60px;
  border: 1.5px solid rgba(100, 100, 100, 1);
  transition: .45s;
}

.icon-card-initials {
  position: absolute;
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 40px;
  font-family: 'Julius Sans One', sans-serif;
  /* color: rgba(50, 50, 50, 1); */
  color: $-theme-header-primary-color;
  transition: .55s;
}

.icon-card-initials-wrapper:hover {
  cursor: pointer;
  border: 1.5px solid $-theme-header-primary-color;
}

.icon-card-initials-wrapper:hover>.icon-card-initials {
  color: $-theme-header-primary-color;
  /* color: black; */
}

.icon-fill {
  background: gainsboro;
}

.initials-name {
  font-size: 1rem;
  font-weight: bolder;
  font-family: 'Julius Sans One', sans-serif;
  letter-spacing: 0.095em;
}

.initials-name>.light-weight {
  font-weight: 100;
}

.initials-name>.bold-weight {
  font-weight: bold;
}

.initials-subscript {
  font-size: .75rem;
  font-weight: lighter;
  margin-top: 2vh;
}

/* Navigation Styles */

.navigation-links-wrapper {
  height: 50%;
  width: 100%;
  z-index: 100;
}

.navigation-links {
  /* border-top: 1px solid black; */
  padding: 0;
  /* margin-top: 5vh; */
  list-style: none;
}

.navigation-links>li {
  border-bottom: 1px solid black;
  cursor: pointer;
  padding: 10px;
  transition: .3s;
}

.navigation-links>li:hover {
  color: #235391;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

.nav-socials-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 2%;
}

.nav-socials-links {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0px;
}

.fade-in {
  opacity: 1;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.fade-out {
  opacity: 0;
  transform: translateX(100px);
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  -o-transform: translateX(100px);
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  pointer-events: none;
}

/* Flavor Tags */
.html-tag-open,
.html-tag-close {
  position: absolute;
  display: inherit;
  transform: translate(18px, 20px);
}

.fa-linkedin-square {
  font-size: 70px;
  color: gray;
  transition: .5s;
}

.fa-github-square {
  font-size: 70px;
  color: gray;
  transition: .5s;
}


.fa-linkedin-square:hover,
.fa-github-square:hover {
  /* font-size: 100px; */
  transition: .5s;
  color: #A7BCD6;
  /* transform: translateY(10px); */
}

.floater-button-navbar {
  width: 50%;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .navbar-component {
    width: 70vw;
    right: 0;
  }
  .fa-linkedin-square {
    font-size: 40px;
  }

  .fa-github-square {
    font-size: 40px;
  }  
}

@media (min-width: 1000px) {
  .navbar-component {
    display: fixed;
    right: 0;
    top: 15vh;
    width: 250px;
    height: 50vh;
  }

  .navigation-links {
    font-size: 1rem;
  }

  .nav-socials-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    font-size: 1rem;
  }

  .fa-linkedin-square {
    font-size: 30px;
    color: gray;
  }

  .fa-github-square {
    font-size: 30px;
    color: gray;
  }

  .fa-linkedin-square:hover,
  .fa-github-square:hover {
    color: #A7BCD6;
  }
}